import React from "react";
import { Box, Typography, Stack, Container } from "@mui/material";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const SharedHeader = ({
  subheading,
  heading,
  organisation,
  styleClass,
  displayLogo = true,
}) => {
  return (
    <>
      <Box className={`SharedHeader ${styleClass}`}>
        <Container>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="column" spacing={1} justifyContent="center">
              <Typography className="SharedHeader-sub-heading">
                {subheading}
              </Typography>
              <Stack direction="row" spacing={2}>
                <Box className="SharedHeader-sideline"></Box>
                <Typography className="SharedHeader-heading">
                  {heading}
                </Typography>
              </Stack>
            </Stack>
            {organisation && displayLogo && (
              <Box className="organisation-logo-container">
                <Box
                  component="img"
                  alt={organisation.name}
                  className="organisation-logo"
                  src={organisation.logo}
                />
              </Box>
            )}
          </Stack>
        </Container>
        <Box className="bottomspace-sharedheader" />
      </Box>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    organisation: state.organisation.organisation,
  };
};
SharedHeader.propTypes = {
  subheading: PropTypes.string,
  heading: PropTypes.string,
  styleClass: PropTypes.string,
  organisation: PropTypes.object,
  displayLogo: PropTypes.bool,
};

export default connect(mapStateToProps)(SharedHeader);
