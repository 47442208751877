import { Box, Typography } from "@mui/material";
import { useState } from "react";
import PropTypes from "prop-types";
import { TRACKING_HOME_OPTIONS } from "../../../../../constants";
import LoadingButton from "../../../../generic-components/button";
import { useNavigate } from "react-router-dom";

const ButtonSection = ({
  heading,
  buttonTitle,
  secondButtonTitle,
  link,
  secondLink,
  handleClick,
  navigation = false,
  newTab = false,
}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleRedirection = (url, navigation, newTab) => {
    setLoading(true);
    if (navigation) {
      navigate(url);
    } else if (!navigation && !newTab) {
      window.location.href = url;
    } else if (!navigation && newTab) {
      window.open(url, "_blank");
    }
  };

  return (
    <Box className="button-section-container">
      <Typography
        className={
          heading
            ? `button-typography ${secondButtonTitle ? "mb-30" : "mb-18"}`
            : ""
        }
      >
        {heading}
      </Typography>
      <Box className={secondButtonTitle ? "dual-button" : ""}>
        <LoadingButton
          buttonTitle={buttonTitle}
          loading={loading && buttonTitle !== "FAQ"}
          trackingDetails={TRACKING_HOME_OPTIONS}
          handleClick={() => {
            handleClick
              ? handleClick()
              : handleRedirection(link, navigation, newTab);
          }}
          styleClass={
            secondButtonTitle
              ? "btn primary-clr-btn smallbtn"
              : "btn primary-clr-btn"
          }
        />
        {secondButtonTitle && (
          <LoadingButton
            buttonTitle={secondButtonTitle}
            trackingDetails={TRACKING_HOME_OPTIONS}
            handleClick={() => {
              handleClick
                ? handleClick()
                : handleRedirection(secondLink, navigation, newTab);
            }}
            styleClass={
              secondButtonTitle
                ? "btn primary-clr-btn smallbtn"
                : "btn primary-clr-btn"
            }
          />
        )}
      </Box>
    </Box>
  );
};

ButtonSection.propTypes = {
  navigation: PropTypes.bool,
  heading: PropTypes.string,
  buttonTitle: PropTypes.string,
  secondButtonTitle: PropTypes.string,
  link: PropTypes.string,
  secondLink: PropTypes.string,
  newTab: PropTypes.bool,
  handleClick: PropTypes.func,
};

export default ButtonSection;
