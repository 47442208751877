import React, { useEffect } from "react";
import Zendesk from "react-zendesk";
import useUserEvent from "../../../hooks/useUserEvent";
import PropTypes from "prop-types";
import useStepper from "../../../hooks/useStepper";

const ZENDESK_KEY = "11b49c5b-c889-447f-8850-950cb34365f3";

const Chatbtn = ({ trackingDetails }) => {
  const { userTrackingMutation } = useUserEvent();
  const { trackingCode } = useStepper();

  const handleUserEvent = (element) =>
    userTrackingMutation({
      variables: {
        ...trackingDetails,
        field_name: element?.current?.textContent || "Chat",
        field_value: element?.current?.value?.toString() || "Clicked",
        avc_track_code: trackingCode,
      },
    });

  const liveChatTrigger = () => {
    window?.zE && window?.zE("messenger", "show");
    window?.zE && window?.zE("messenger", "open");
  };

  const hendleAfterLoad = () => {
    window?.zE && window?.zE("messenger", "close");

    window?.zE &&
      window?.zE("messenger:on", "open", () => {
        handleUserEvent();
      });
  };

  useEffect(() => {
    let timeoutId;

    const resetTimer = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        liveChatTrigger();
      }, process.env.REACT_APP_INACTIVITY_TIMEOUT || 180000);
    };

    const events = [
      "mousedown",
      "mousemove",
      "keydown",
      "keypress",
      "scroll",
      "touchstart",
    ];

    events.forEach((event) => {
      document.addEventListener(event, resetTimer);
    });

    resetTimer();

    return () => {
      events.forEach((event) => {
        document.removeEventListener(event, resetTimer);
      });
    };
  }, []);

  return (
    <>
      <Zendesk
        defer
        zendeskKey={ZENDESK_KEY}
        onLoaded={() => hendleAfterLoad()}
      />
    </>
  );
};

Chatbtn.propTypes = {
  trackingDetails: PropTypes.object,
};

export default Chatbtn;
