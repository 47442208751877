import { useLazyQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getExternalSupplierDetails } from "../../../../graphql/queries/OrganisationDetails";
import useStepper from "../../../../hooks/useStepper";
import StaffBenefitsButton from "../../../generic-components/othersStaffBenefitsButton";

const StaffBenefitsWidget = ({ trackingDetails, organisation }) => {
  const { setSaveError, setErrorToast } = useStepper();

  const [isSSOEnabled, setIsSSOEnabled] = useState(false);

  const [fetchOtherStaffBenefitsDetails] = useLazyQuery(
    getExternalSupplierDetails
  );

  const getStaffBenefitDetails = () => {
    fetchOtherStaffBenefitsDetails({
      variables: {
        organisation_id: organisation?.id,
      },
      onCompleted: (data) => {
        setIsSSOEnabled(data?.external_suppliers[0]?.sso_enabled);
      },
      onError: (error) => {
        setSaveError(error);
        setErrorToast(true);
      },
    });
  };

  useEffect(() => {
    if (organisation.id) {
      getStaffBenefitDetails();
    }
  }, [organisation]);

  return (
    <>
      {isSSOEnabled ? (
        <StaffBenefitsButton trackingDetails={trackingDetails} />
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    organisation: state?.organisation?.organisation,
  };
};

StaffBenefitsWidget.propTypes = {
  organisation: PropTypes.object,
  trackingDetails: PropTypes.object,
};

export default connect(mapStateToProps)(StaffBenefitsWidget);
