import { Button, Typography, Box } from "@mui/material";
import vivupLogo from "../../../assets/images/vivup-logo.png";
import useUserEvent from "../../../hooks/useUserEvent";
import PropTypes from "prop-types";
import { useRef } from "react";
import useStepper from "../../../hooks/useStepper";
import { useNavigate } from "react-router-dom";

const StaffBenefitsButton = ({ trackingDetails }) => {
  const { userTrackingMutation } = useUserEvent();
  const { trackingCode } = useStepper();
  const navigate = useNavigate();

  const staffBtnRef = useRef();

  const handleUserEvent = (element) =>
    userTrackingMutation({
      variables: {
        ...trackingDetails,
        field_name:
          element?.current?.textContent || "Explore your other staff benefits",
        field_value: element?.current?.value?.toString() || "Clicked",
        avc_track_code: trackingCode,
      },
    });

  return (
    <Button
      ref={staffBtnRef}
      className="staffBenefitsButton"
      aria-label="Staff Benefits Button"
      data-type="Staff Benefits"
      onClick={() => {
        handleUserEvent(staffBtnRef);
        navigate("/staff_benefits");
      }}
    >
      <Box
        component="img"
        src={vivupLogo}
        alt="staff-benefits"
        className="vivup-icon"
      />
      <Typography className="content">
        Explore your other staff benefits
      </Typography>
    </Button>
  );
};

StaffBenefitsButton.propTypes = {
  trackingDetails: PropTypes.object,
};

export default StaffBenefitsButton;
