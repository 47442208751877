import { Box, Container, Stack, Typography } from "@mui/material";
import { TRACKING_STAFF_BENEFITS_OPTIONS } from "../../../../constants";
import LoadingButton from "../../../generic-components/button";
import SharedHeader from "../../../generic-components/shared-header/SharedHeader";
import VivupSSO from "../../../../assets/images/Vivup-AVC-Wise-SSO.png";
import { useLazyQuery } from "@apollo/client";
import { getExternalSupplierDetails } from "../../../../graphql/queries/OrganisationDetails";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import useStepper from "../../../../hooks/useStepper";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getCookie } from "../../../../utils/cookies";
import Loader from "../../../generic-components/loader";
import ssoService from "../../../../api/modules/sso";

const StaffBenefitsPage = ({ organisation }) => {
  const { setSaveError, setErrorToast } = useStepper();
  const [isLoading, setIsLoading] = useState(true);
  const [actionDetails, setActionDetails] = useState(null);
  const navigate = useNavigate();

  const [fetchOtherStaffBenefitsDetails] = useLazyQuery(
    getExternalSupplierDetails
  );

  const fetchSSOActionURL = async () => {
    try {
      const token = getCookie("user_session");
      const response = await ssoService.getSSOActionURL(organisation.id, token);
      setIsLoading(false);
      setActionDetails(response?.data);
    } catch (error) {
      setIsLoading(false);
      setSaveError(error);
      setErrorToast(true);
    }
  };

  const getStaffBenefitDetails = () => {
    fetchOtherStaffBenefitsDetails({
      variables: {
        organisation_id: organisation?.id,
      },
      onCompleted: (data) => {
        if (!data?.external_suppliers[0]?.sso_enabled) {
          navigate("/home");
          return;
        }
        fetchSSOActionURL();
      },
      onError: (error) => {
        setIsLoading(false);
        setSaveError(error);
        setErrorToast(true);
      },
    });
  };

  useEffect(() => {
    if (organisation.id) {
      getStaffBenefitDetails();
    }
  }, [organisation]);

  return (
    <Box className="application-page-container mb-100">
      {isLoading ? (
        <Box className="mt-150">
          <Loader />
        </Box>
      ) : (
        <>
          <SharedHeader heading="Other Benefits" />
          <Container className="mt-150">
            <Stack
              direction={{ md: "row", sm: "column" }}
              justifyContent="space-between"
              spacing={2}
            >
              <Stack direction="column">
                <Typography className="primary-text large-heading">
                  <strong>
                    Our Vivup staff benefits support your wellbeing
                  </strong>
                </Typography>
                <Typography className="mt-18">
                  Access our Vivup website now for an exciting range of other
                  benefits designed to help your physical, financial and mental
                  health wellbeing.
                </Typography>
                <Typography className="mt-18">
                  Our benefits include Lifestyle Savings, which provides access
                  to a huge range of discounts across the UK's major retailers
                  including technology, fashion, supermarkets, travel and much
                  more, meaning you can save money on the things that matter.
                  Visit our Lifestyle Savings staff benefit now and explore the
                  wide range of frequently updated discounted eGift cards,
                  including Currys PC World, Argos, Primark, ASOS, B&Q and top
                  supermarkets like Sainsbury's, Asda and Tesco!
                </Typography>
                <form action={actionDetails?.action_url} method="POST">
                  <input
                    hidden
                    value={actionDetails?.saml_response}
                    name={actionDetails?.field_name}
                  />
                  <LoadingButton
                    buttonTitle="Access my vivup staff benefits"
                    type="submit"
                    disabled={!actionDetails}
                    trackingDetails={TRACKING_STAFF_BENEFITS_OPTIONS}
                    styleClass="btn primary-clr-btn mt-30 mb-30"
                  />
                </form>
              </Stack>
              <Box className="mt-18 saving-jars-container">
                <Box component="img" alt="vivup-sso" src={VivupSSO} />
              </Box>
            </Stack>
          </Container>
        </>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    organisation: state?.organisation?.organisation,
  };
};

StaffBenefitsPage.propTypes = {
  organisation: PropTypes.object,
};

export default connect(mapStateToProps)(StaffBenefitsPage);
